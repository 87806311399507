import React, { ChangeEvent, useState } from 'react'
import styles from './SignIn.module.css'
import StandardInput from '../../../components/inputs/standardInput/StandardInput'
import DynamicButton from '../../../components/dynamicbuton/DynamicButton'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../../components/alertmessage/AlertMessage';

interface FormData {
  [key: string]: string;
}


type AlertType = {
  type: 'error' | 'success', message: string
}


const SignIn = () => {
  const [creds, setCreds] = useState<FormData>({});
  const [isLogging, setIsLogging] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType | null>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phone" && !/^\d*$/.test(value)) return showAlert({ type: "error", message: "Mobile number must be in number!" });
    setCreds((prevDate) => ({ ...prevDate, [name]: value }))
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!creds.phone) return showAlert({ type: 'error', message: 'Mobile number is missing!' });
    if (!creds.password) return showAlert({ type: 'error', message: 'Password is missing!' });
    login(creds);
  };

  const login = async (form: any) => {
    try {
      setIsLogging(true);
      const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(baseUrl + "/api/drivers/sign-in", form);
      const token = response.data.token;
      localStorage.setItem("token", JSON.stringify(token));
      window.location.href = "/driver"
    } catch (error: any) {
      console.log(error);
      const errorMessage = error.response?.data?.errorMessage || "Internal server error!";
      showAlert({ type: 'error', message: errorMessage });
    } finally {
      setIsLogging(false);
    }
  };

  const showAlert = (data: AlertType) => {
    setAlert({ type: data.type, message: data.message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  return (
    <div className={styles.page}>
      {
        alert &&
        <AlertMessage type={alert.type} message={alert.message} />
      }
      <div className={styles.spacer}></div>
      <div className={styles.section}>
        <h1>Driver Sign-in</h1>
        <form onSubmit={onSubmit} className={styles.form}>
          <StandardInput id={'phone'} label={'Mobile number'} name={'phone'} value={creds.phone ? creds.phone : ''} inputType={'text'} placeHolder='enter registered mobile number' maxLenght={10} onChange={handleInputChange} />
          <StandardInput id={'password'} label={'Password'} name={'password'} value={creds.password ? creds.password : ''} inputType={'password'} placeHolder='enter password' isPasswsord={true} onChange={handleInputChange} />
          <a className={styles.forgotPassword} href="/driver/forgot-password">Forgot Password</a>
          {
            isLogging ?
              <DynamicButton label='Loging in......' isHaveBackground={true} isRounded={false} disabled={true} />
              :
              <DynamicButton label='Sign-in' buttonType={'submit'} isHaveBackground={true} isRounded={false} />
          }
        </form>
        <p className={styles.regText}>Don't have account yet? <a href="/driver/registration">Register now</a></p>
      </div>
      <div className={styles.spacer}></div>
    </div>
  )
}

export default SignIn