"use client"
import React, { ChangeEvent, useState } from 'react'
import styles from './StandardInput.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';


interface StandardInputProps {
    id: string;
    label: string;
    name: string;
    value: string;
    inputType: string;
    accept?:string;
    placeHolder?: string;
    maxLenght?: number;
    isPrice?: boolean;
    isPasswsord?: boolean;
    backgroundColor?: string;
    passwordHoverColor?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const StandardInput = ({ id, label, name, inputType,accept, value, placeHolder, maxLenght, isPrice, isPasswsord, backgroundColor, passwordHoverColor, onChange }: StandardInputProps) => {
    const [type, setType] = useState<string>(inputType);
    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
    const [isHover, setHover] = useState<boolean>(false);

    const passwordToggleBtn = () => {
        setPasswordVisible(!isPasswordVisible);
        if (type === 'password') {
            setType('text');
        } else {
            setType('password');
        }
    }

    return (
        <div className={styles.container} style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
            <label className={styles.label} htmlFor={id}>{label}</label>
            <div className={styles.inputContainer}>
                {isPrice && <p>₹</p>}
                <input className={styles.input} id={id} type={type} name={name} value={value} placeholder={placeHolder} maxLength={maxLenght} accept={accept} onChange={onChange} />
            </div>
            {
                isPasswsord &&
                <div className={styles.buttonContainer} style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
                    <button
                        type='button'
                        className={styles.lockIcon}
                        onClick={passwordToggleBtn}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        style={isHover ? { backgroundColor: passwordHoverColor } : { backgroundColor: 'transparent' }}
                    >
                        <FontAwesomeIcon icon={isPasswordVisible ? faUnlock : faLock} />
                    </button>
                </div>
            }
        </div>
    )
}

export default StandardInput