import React from 'react'
import styles from './BottomFooters.module.css'

function BottomFooters() {
    const currentYear = new Date().getFullYear();
    return (
        <section className={styles.container}>
            <p className={styles.copyright}>
                &copy; {currentYear} UraCab Private Limited. All rights reserved.
            </p>
            <ul className={styles.links}>
                <li>
                    <a className={styles.link} href="/privacy&policies">Privacy Policies</a>
                </li>

                <div className={styles.divider}></div>
                <li>
                    <a className={styles.link} href="/termsofuse">Terms of use</a>
                </li>
            </ul>
        </section>
    )
}

export default BottomFooters