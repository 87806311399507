import React, { ChangeEvent, useState } from 'react'
import styles from './ResetPassword.module.css'
import StandardInput from '../../../components/inputs/standardInput/StandardInput'
import DynamicButton from '../../../components/dynamicbuton/DynamicButton'
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import AlertMessage from '../../../components/alertmessage/AlertMessage';


type AlertType = {
  type: 'error' | 'success', message: string
}


type Form = {
  password: string;
  re_password: string;
}


const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [formData, setFormData] = useState<Form>({ password: '', re_password: '' });
  const [isSending, setIsSending] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType | null>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.password) return showAlert({ type: 'error', message: 'Please enter new password!' });
    if (!formData.re_password) return showAlert({ type: 'error', message: 'Please re-enter new password!' });
    if (formData.password !== formData.re_password) return showAlert({ type: 'error', message: 'Password not matching!' });
    if (!token) return showAlert({ type: 'error', message: 'Token not found!' });
    resetPassword(formData);
  };

  const resetPassword = async (form: any) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    try {
      setIsSending(true);
      const response = await axios.post(baseUrl + "/api/drivers/reset-password", form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const message = response.data.message;
      setAlert({ type: 'success', message: 'New password updated!' });
    } catch (error: any) {
      console.log(error);
      const errorMessage = error.response?.data?.errorMessage || "Internal server error!";
      showAlert({ type: 'error', message: errorMessage });
    } finally {
      setIsSending(false);
    }
  };

  const showAlert = (data: AlertType) => {
    setAlert({ type: data.type, message: data.message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  return (
    <div className={styles.page}>
      {
        alert &&
        <AlertMessage type={alert.type} message={alert.message} buttonLabel={alert.type === 'success' ? "Sign-in now" : ""} onButtonClick={() => {
          window.location.href = "/driver/signin";
        }} />
      }
      <div className={styles.spacer}></div>
      <div className={styles.section}>
        <h1>Reset New Password</h1>
        <form onSubmit={onSubmit} className={styles.form}>
          <StandardInput id={'password'} label={'New Password'} name={'password'} value={formData.password} inputType={'password'} placeHolder='enter new password' isPasswsord={true} onChange={handleInputChange} />
          <StandardInput id={'re_password'} label={'Re-enter Password'} name={'re_password'} value={formData.re_password} inputType={'text'} placeHolder='re-enter new password' onChange={handleInputChange} />

          {
            isSending ?
              <DynamicButton label='Password reseting......' isHaveBackground={true} isRounded={false} disabled={true} />
              :
              <DynamicButton label='Reset Password' buttonType={'submit'} isHaveBackground={true} isRounded={false} />
          }
        </form>
      </div>
      <div className={styles.spacer}></div>
    </div>
  )
}

export default ResetPassword