import React, { ChangeEvent, useState } from 'react'
import styles from './ForgotPassword.module.css'
import StandardInput from '../../../components/inputs/standardInput/StandardInput'
import DynamicButton from '../../../components/dynamicbuton/DynamicButton'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../../components/alertmessage/AlertMessage';
import VerifyOtp from '../../../components/verify-otp/VerifyOtp';

interface FormData {
  [key: string]: string;
}


type AlertType = {
  type: 'error' | 'success', message: string
}


const ForgotPassword = () => {
  const [phone, setPhone] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [otpRespose, setOtpResponse] = useState<string | null>(null);
  const [alert, setAlert] = useState<AlertType | null>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) return showAlert({ type: "error", message: "Mobile number must be in number!" });
    setPhone(value);
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!phone) return showAlert({ type: 'error', message: 'Mobile number is missing!' });
    sendOtp({ mobile: phone });
  };

  const sendOtp = async (form: any) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    try {
      setIsSending(true);
      const response = await axios.post(baseUrl + "/api/auth/send-otp", form);
      const token = response.data.token;
      setOtpResponse(token);
    } catch (error: any) {
      console.log(error);
      const errorMessage = error.response?.data?.errorMessage || "Internal server error!";
      showAlert({ type: 'error', message: errorMessage });
    } finally {
      setIsSending(false);
    }
  };

  const handleResend = () => {
    sendOtp({ mobile: phone });
  }

  const handleVerified = async (newToken: string) => {
    window.location.href = "/driver/reset-password?token=" + newToken;
  }

  const showAlert = (data: AlertType) => {
    setAlert({ type: data.type, message: data.message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  return (
    <div className={styles.page}>
      {
        alert &&
        <AlertMessage type={alert.type} message={alert.message} />
      }
      <div className={styles.spacer}></div>
      <div className={styles.section}>
        <h1>Forgot Password</h1>
        <form onSubmit={onSubmit} className={styles.form}>
          <StandardInput id={'phone'} label={'Mobile number'} name={'phone'} value={phone} inputType={'text'} placeHolder='enter registered mobile number' maxLenght={10} onChange={handleInputChange} />
          {
            isSending ?
              <DynamicButton label='OTP Sending......' isHaveBackground={true} isRounded={false} disabled={true} />
              :
              <DynamicButton label='Send OTP' buttonType={'submit'} isHaveBackground={true} isRounded={false} />
          }
        </form>
      </div>
      <div className={styles.spacer}></div>

      {
        otpRespose &&
        <VerifyOtp mobile={phone} token={otpRespose} onResend={handleResend} onError={(e) => showAlert({ type: 'error', message: e })} onClose={() => setOtpResponse(null)} onVerified={handleVerified} />
      }
    </div>
  )
}

export default ForgotPassword