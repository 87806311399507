import React from 'react'
import styles from './Demo.module.css'

function Demo({ isShow }: { isShow: boolean }) {
    // const openWindow = () => {
    //     window.open('https://www.figma.com/proto/EFgJkB2Gtr0KSRq7TpqoAR/User-Interface?node-id=1-1784&node-type=frame&t=atgEhJVRTUZf5Rwe-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A1784', '_blank', 'noopener,noreferrer');
    //   };

    const hanleLinkClick = ()=>{
        window.location.href = "/drivers"
    }
    return (
        <div className={`${styles.container} ${isShow ? styles.show : ''}`}>
            <div className={styles.section}>
                <button onClick={hanleLinkClick}>Driver registration</button>
            </div>
        </div>
    )
}

export default Demo