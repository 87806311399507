import React, { ChangeEvent, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from './Registration.module.css'
import StandardInput from '../../../components/inputs/standardInput/StandardInput';
import DynamicButton from '../../../components/dynamicbuton/DynamicButton';
import SelectInput from '../../../components/inputs/selectInput/SelectInput';
import axios from 'axios';
import AlertMessage from '../../../components/alertmessage/AlertMessage';
import VerifyOtp from '../../../components/verify-otp/VerifyOtp';

interface FormData {
    [key: string]: string;
}

const validateDate = (date: string) => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    if (!regex.test(date)) {
        return false;
    }
    return true;
};


type AlertType = {
    type: 'error' | 'success', message: string
}

const Registration: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({})
    const [alert, setAlert] = useState<AlertType | null>(null);
    const [otpRespose, setOtpResponse] = useState<string | null>(null);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isAccepted, setAccept] = useState<boolean>(false);

    // const { type } = useParams();
    // const [searchParams] = useSearchParams();
    // const driverId = searchParams.get("driverId");
    // if (type !== "profile" && !driverId) return null

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if (name === 'driver_contact' && !/^\d*$/.test(value)) return showAlert({ type: 'error', message: "Mobile number must be in number!" });
        const isBackspace = (event.nativeEvent as InputEvent).inputType === 'deleteContentBackward';
        if (name === "driver_dob") {
            const input = value.replace(/\D/g, '');
            let formattedDate = '';
            if (input.length > 0) {
                formattedDate += input.slice(0, 2);
            }
            if (input.length >= 2) {
                if (!isBackspace || (isBackspace && input.length > 2)) {
                    formattedDate += '-' + input.slice(2, 4);
                }
            }
            if (input.length >= 4) {
                if (!isBackspace || (isBackspace && input.length > 4)) {
                    formattedDate += '-' + input.slice(4, 8);
                }
            }
            const dateParts = formattedDate.split('-');
            if (dateParts.length === 3) {
                const month = parseInt(dateParts[1], 10);
                const day = parseInt(dateParts[0], 10);

                if (month > 12) {
                    return showAlert({ type: 'error', message: 'Month cannot be greater than 12' });
                }
                if (day > 31) {
                    return showAlert({ type: 'error', message: 'Day cannot be greater than 31' });
                }
            }
            return setFormData(prevData => ({ ...prevData, [name]: formattedDate }));
        }
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formData.first_name) return showAlert({ type: 'error', message: 'First name is missing!' })
        if (!formData.last_name) return showAlert({ type: 'error', message: 'Last name is missing!' })
        if (!formData.driver_gender) return showAlert({ type: 'error', message: 'Gender is missing!' })
        if (!formData.driver_dob) return showAlert({ type: 'error', message: 'Date of birth is missing!' })
        if (!formData.driver_address) return showAlert({ type: 'error', message: 'Address is missing!' })
        if (!formData.driver_contact) return showAlert({ type: 'error', message: 'Contact number is missing!' })
        if (!formData.password) return showAlert({ type: 'error', message: 'Password is missing!' })
        if (!formData.re_password) return showAlert({ type: 'error', message: 'Please re-enter password!' })
        if (formData.password !== formData.re_password) return showAlert({ type: 'error', message: 'Password not maching!' })
        if (!validateDate(formData.driver_dob)) return showAlert({ type: 'error', message: 'Invalid date format in date of birth' });
        if (!isAccepted) return showAlert({ type: 'error', message: 'Accept the terms and privacy policies,please' });
        formData.mobile = formData.driver_contact;
        sendOtp(formData);
    };

    const handleResend = () => {
        formData.mobile = formData.driver_contact;
        sendOtp(formData);
    }

    const sendOtp = async (form: any) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        try {
            setIsSending(true);
            const response = await axios.post(baseUrl + "/api/auth/send-otp", form);
            console.log(response);
            const token = response.data.token;
            setOtpResponse(token);
        } catch (error: any) {
            const errorMessage = error.response.data?.errorMessage || "Internal server error!";
            showAlert({ type: 'error', message: errorMessage });
            console.log(error);
        } finally {
            setIsSending(false);
        }
    }

    const showAlert = (data: AlertType) => {
        setAlert({ type: data.type, message: data.message })
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }

    const handleVerified = async (newToken: string) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        if (!newToken) {
            showAlert({ type: 'error', message: "Something went wrong!" });
            return;
        }
        try {
            const response = await axios.get(baseUrl + `/api/drivers/register`,
                {
                    headers: {
                        Authorization: `Bearer ${newToken}`,
                    },
                }
            );

            const token = response.data?.token;
            if (token) {
                localStorage.setItem("token", JSON.stringify(token));
                window.location.href = "/driver/docs";
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.errorMessage || "Internal Server Error!";
            showAlert({ type: 'error', message: errorMessage });
            setOtpResponse(null);
        }
    }

    return (
        <div className={styles.page}>
            {
                alert &&
                <AlertMessage type={alert.type} message={alert.message} />
            }
            <div className={styles.spacer}></div>
            <div className={styles.section}>
                <h1>Driver Registration</h1>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formSection}>
                        <h3>Profile</h3>
                        <div className={styles.inputs}>
                            <StandardInput id={'first_name'} label={'First Name'} name={'first_name'} value={formData.first_name ? formData.first_name : ''} inputType={'text'} placeHolder='enter your first name' onChange={handleInputChange} />
                            <StandardInput id={'middle_name'} label={'Middle Name'} name={'middle_name'} value={formData.middle_name ? formData.middle_name : ''} inputType={'text'} placeHolder='enter your middlename' onChange={handleInputChange} />
                            <StandardInput id={'last_name'} label={'Last Name'} name={'last_name'} value={formData.last_name ? formData.last_name : ''} inputType={'text'} placeHolder='enter your lastname' onChange={handleInputChange} />
                            <SelectInput id={'driver_gender'} label={'Gender'} name={'driver_gender'} value={formData.driver_gender ? formData.driver_gender : ''} options={[{ label: "--Select Gender--", value: '' }, { label: "Male", value: 'male' }, { label: "Female", value: 'female' }, { label: "Others", value: 'others' }]} onChange={handleInputChange} />
                            <StandardInput id={'driver_dob'} label={'Date of Birth'} name={'driver_dob'} value={formData.driver_dob ? formData.driver_dob : ''} inputType={'text'} placeHolder='dd-mm-yyyy' onChange={handleInputChange} />
                            <StandardInput id={'driver_address'} label={'Address'} name={'driver_address'} value={formData.driver_address ? formData.driver_address : ''} inputType={'text'} placeHolder='enter your address' onChange={handleInputChange} />

                        </div>
                    </div>

                    <div className={styles.formSection}>
                        <h3>Contacts</h3>
                        <div className={styles.inputs}>
                            <div>
                                <StandardInput id={'driver_contact'} label={'Phone Number'} name={'driver_contact'} value={formData.driver_contact ? formData.driver_contact : ''} inputType={'text'} maxLenght={10} placeHolder='enter your contact' onChange={handleInputChange} />
                                <p className={styles.phoneNotice}>Please ensure your mobile number is correct, as it will be used for logging in.</p>
                            </div>
                            <StandardInput id={'driver_email'} label={'Email (optional)'} name={'driver_email'} value={formData.driver_email ? formData.driver_email : ''} inputType={'email'} placeHolder='enter your email address' onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className={styles.formSection}>
                        <h3>Passwords</h3>
                        <div className={styles.inputs}>
                            <StandardInput id={'password'} label={'Password'} name={'password'} value={formData.password ? formData.password : ''} inputType={'password'} placeHolder='enter password' isPasswsord={true} onChange={handleInputChange} />
                            <StandardInput id={'re_password'} label={'Re-enter Password'} name={'re_password'} value={formData.re_password ? formData.re_password : ''} inputType={'text'} placeHolder='re-enter password' onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className={styles.checkboxContainer}>
                        <input type="checkbox" checked={isAccepted} onChange={(e) => setAccept(e.target.checked)} />
                        <a href="https://sites.google.com/view/uracabprivacypolicy/home" target="_blank">Accept the terms and privacy policies</a>
                    </div>
                    <DynamicButton label={isSending ? 'Sending OTP...' : 'Register'} buttonType={'submit'} isHaveBackground={true} isRounded={false} disabled={isSending} />
                </form>
                <p className={styles.signInText}>Already registered? <a href="/driver/signin">Sign in</a></p>
            </div>
            <div className={styles.spacer}></div>
            {
                otpRespose &&
                <VerifyOtp mobile={formData.driver_contact} token={otpRespose} onResend={handleResend} onError={(e) => showAlert({ type: 'error', message: e })} onClose={() => setOtpResponse(null)} onVerified={handleVerified} />
            }

        </div>
    )
}

export default Registration