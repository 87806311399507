import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { lazy, Suspense, useEffect, useRef } from 'react';
import styles from './App.module.css'
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ShowMessage from './pages/message/ShowMessage';
import PageError from './pages/pageError/PageError';
import Loading from './pages/loading/Loading';
import Demo from './pages/demo/Demo';
import Driver from './pages/drivers/Drivers';
import Registration from './pages/drivers/resgistration/Registration';
import Profile from './pages/driver/profile/Profile';
import Trips from './pages/driver/trips/Trips';
import Earnings from './pages/driver/earnings/Earnings';
import SignIn from './pages/drivers/signin/SignIn';
import Docs from './pages/drivers/docs/Docs';
import Files from './pages/drivers/files/Files';
import Validate from './pages/drivers/validate/Validate';
import ForgotPassword from './pages/drivers/forgotpassword/ForgotPassword';
import ResetPassword from './pages/drivers/resetpassword/ResetPassword';

const Home = lazy(() => import('./pages/home/Home'));
const About = lazy(() => import('./pages/about/About'));
const Services = lazy(() => import('./pages/services/Services'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Team = lazy(() => import('./pages/team/Team'));
const PrivacyPolicies = lazy(() => import('./pages/privacypolicies/PrivacyPolicies'));
const TermOfuse = lazy(() => import('./pages/termsofuse/TermsOfUse'));
const Person = lazy(() => import('./pages/person/Person'));
const queryClient = new QueryClient();

const getToken = () => {
  return localStorage.getItem('token');
}

function App() {
  const HomeLayout = () => {
    const { pathname } = useLocation();
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }, [pathname]);

    return (
      <div className={styles.main} ref={contentRef}>
        <Demo isShow={pathname == "/"} />
        <header className={styles.header}>
          <Header />
        </header>
        <main className={styles.content}>
          <Suspense fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
              <Outlet />
            </QueryClientProvider>
          </Suspense>
        </main>
        <footer className={styles.footer}>
          <Footer />
        </footer>
      </div>
    );
  };

  const DriverLayout = () => {
    const { pathname } = useLocation();
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }, [pathname]);

    return (
      <div className={styles.main} ref={contentRef}>
        {/* <header className={styles.header}>
          <DriverHeader />
        </header> */}
        <main className={styles.content}>
          <Suspense fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
              <Outlet />
            </QueryClientProvider>
          </Suspense>
        </main>
        {/* <footer className={styles.footer}>
          <DriverFooter />
        </footer> */}
      </div>
    );
  };

  const AuthLayout: React.FC<{ element: JSX.Element }> = ({ element }) => {
    const token = getToken();
    if (!token) {
      return <Navigate to="/driver/registration" replace />;
    }
    return element;
  };

  const ProtectedRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    if (!token) {
      return <Navigate to="/driver/registration" replace />;
    }
    return element;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team/:name" element={<Person />} />
        </Route>
        <Route path="/driver" element={<AuthLayout element={<DriverLayout />} />}>
          <Route path="/driver" element={<Validate />} />
          {/* <Route path="/driver/profile" element={<Profile />} />
          <Route path="/driver/trips" element={<Trips />} />
          <Route path="/driver/earnings" element={<Earnings />} /> */}
          <Route path="/driver/docs" element={<Docs />} />
          <Route path="/driver/files" element={<Files />} />
        </Route>
        <Route path="/privacy&policies" element={<PrivacyPolicies />} />
        <Route path="/termsofuse" element={<TermOfuse />} />
        <Route path="/alert" element={<ShowMessage />} />
        <Route path="/drivers" element={<Driver />} />
        <Route path="/driver/signin" element={<SignIn />} />
        <Route path="/driver/forgot-password" element={<ForgotPassword />} />
        <Route path="/driver/reset-password" element={<ResetPassword />} />
        <Route path="/driver/registration" element={<Registration />} />
        <Route path="/*" element={<PageError />} />
      </Routes>
    </Router>
  );
}

export default App;
