import axios from 'axios';
import React, { useEffect, useState } from 'react'
import styles from './Validate.module.css'
import DynamicButton from '../../../components/dynamicbuton/DynamicButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRightFromBracket, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

const initialFiles = [
    { title: "Passport Photo", description: "", name: "passport" },
    { title: "PAN", description: "Permanent Account Number", name: "pan" },
    { title: "Driving License", description: "", name: "driving_license" },
    { title: "Address Proof", description: "", name: "prc" },
    { title: "Bank Account", description: "", name: "bank" },
    { title: "EPIC", description: "", name: "epic" },
    { title: "Vehicle RC", description: "Vehicle Registration Certificate", name: "vehicle_rc" },
    { title: "Vehicle Permit", description: "", name: "vehicle_permit" },
    { title: "Vehicle Fitness", description: "", name: "vehicle_fitness" }
]


interface FormData {
    [key: string]: string;
}


const Validate = () => {
    const [files, setFiles] = useState<FormData[]>(initialFiles);
    const [emptyFiles, setEmptyFiles] = useState<any[]>([]);
    const [responseData, setResponseData] = useState<any | null>(null);

    useEffect(() => {
        fetchFiles();
    }, []);

    useEffect(() => {
        if (files.length > 0) {
            const emptyList = files.filter(i => !i.value && !i.path);
            setEmptyFiles(emptyList);
        }
    }, [files]);

    const fetchFiles = async () => {
        const token = localStorage.getItem("token");
        if (token) {
            const parsedToken = JSON.parse(token);
            const baseUrl = process.env.REACT_APP_API_URL;
            try {
                const response = await axios.get(baseUrl +
                    "/api/documents",
                    {
                        headers: {
                            Authorization: `Bearer ${parsedToken}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                const responseData = response.data;
                const files = responseData.document_files;
                mergeData(files);
                console.log(responseData);
                setResponseData(responseData);
            } catch (error: any) {
                setResponseData('dsf');
                console.error("Error removing file:", error);

                const err = error?.response?.data;
                if (err && err.code === "token_error") {
                    window.location.replace("/driver/signin");
                } else {
                    console.error("An unexpected error occurred:", err);
                }
            }
        } else {
            window.location.replace("/driver/signin");
        }
    };

    const mergeData = (data: any) => {
        const merged = initialFiles.map(item => {
            const additionalItem = data.find((add: any) => add.document_type === item.name);
            return {
                ...item,
                value: additionalItem ? additionalItem.document_type : '',
                path: additionalItem ? additionalItem.path : '',
            };
        });
        setFiles(merged);
    };

    const handleUploadClick = (url: string) => {
        window.location.href = url;
    }


    const handleLogoutClick = () => {
        localStorage.removeItem('token');
        window.location.reload();
    }

    const isEmpty = () => {
        const keysToCheck = [
            responseData?.bank?.account_no,
            responseData?.car_rc?.reg_no,
            responseData?.driving_license?.license_no,
            responseData?.family_contact?.id
        ];
        return keysToCheck.some(key => !key);
    };


    return (
        <div className={styles.page}>
            <div className={styles.spacer}></div>
            {
                !responseData ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                    <div className={styles.section}>
                        <div>
                            <h1>{responseData?.driver?.driver_name}</h1>
                            <p style={{ margin: '5px 0px' }}>ID: {responseData?.driver?.driver_ID}</p>
                        </div>
                        {
                            isEmpty() &&
                            <div className={styles.sectionContainer}>
                                <h3>Documents</h3>
                                {
                                    !responseData?.bank?.account_no &&
                                    <p>Bank is not fill up yet!</p>
                                }
                                {
                                    !responseData?.car_rc?.reg_no &&
                                    <p>Car RC is not fill up yet!</p>
                                }
                                {
                                    !responseData?.driving_license?.license_no &&
                                    <p>Driving License is not fill up yet!</p>
                                }
                                {
                                    !responseData?.family_contact?.id &&
                                    <p>Family contacts and address is not fill up yet!</p>
                                }
                                <DynamicButton label='Fillup now' isHaveBackground={true} isRounded={true} onClick={() => handleUploadClick('/driver/docs')} />
                            </div>
                        }
                        <div className={styles.sectionContainer}>
                            {
                                emptyFiles.length > 0 ? (
                                    <>
                                        <h3>Files</h3>
                                        {
                                            emptyFiles.map((e, index) => (
                                                <p key={index}>{e.title} is not uploaded yet!</p>
                                            ))
                                        }
                                        <DynamicButton label='Upload now' isHaveBackground={true} isRounded={true} onClick={() => handleUploadClick('/driver/files')} />
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.successContainer}>
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <p>Account registration process has been completed!</p>
                                        </div>
                                    </>
                                )
                            }
                        </div>

                        <DynamicButton icon={faRightFromBracket} label='Logout' isHaveBackground={true} isRounded={true} onClick={handleLogoutClick} />
                    </div>
                )
            }

            <div className={styles.spacer}></div>
        </div>
    )
}

export default Validate