import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './Docs.module.css';
import StandardInput from '../../../components/inputs/standardInput/StandardInput';
import SelectInput from '../../../components/inputs/selectInput/SelectInput';
import DynamicButton from '../../../components/dynamicbuton/DynamicButton';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../../components/alertmessage/AlertMessage';

type Option = { label: string; value: string; description?: string };

type Input = { label: string; id: string; inputType: string; options?: Option[]; placeHolder?: string };

const sections: { title: string; inputs: Input[]; key: string }[] = [
    {
        title: 'Driving License',
        key: 'driving_license',
        inputs: [
            { label: 'License Number', id: 'license_no', inputType: 'text', placeHolder: 'enter license number' },
            { label: 'Issue Date', id: 'issue_date', inputType: 'text', placeHolder: 'dd-mm-yyyy' },
            { label: 'Valid Upto', id: 'valid_upto', inputType: 'text', placeHolder: 'dd-mm-yyyy' },
        ],
    },
    {
        title: 'Vehicle Registration Certificate',
        key: 'car_rc',
        inputs: [
            { label: 'Registration Number', id: 'reg_no', inputType: 'text', placeHolder: 'enter vehicle registration number' },
            { label: 'Model Number', id: 'model_no', inputType: 'text', placeHolder: 'enter vehicle model number' },
            { label: 'Color', id: 'color', inputType: 'text', placeHolder: 'enter color of the vehicle' },
        ],
    },
    {
        title: 'Family contacts and addresses',
        key: 'family_contact',
        inputs: [
            { label: 'Name 1', id: 'name1', inputType: 'text', placeHolder: 'enter name' },
            { label: 'Address 1', id: 'address1', inputType: 'text', placeHolder: 'enter address' },
            { label: 'Contact 1', id: 'contact1', inputType: 'text', placeHolder: 'enter contact number' },
            { label: 'Name 2', id: 'name2', inputType: 'text', placeHolder: 'enter name' },
            { label: 'Address 2', id: 'address2', inputType: 'text', placeHolder: 'enter address' },
            { label: 'Contact 2', id: 'contact2', inputType: 'text', placeHolder: 'enter contact number' },
        ],
    },
    {
        title: 'Bank Account',
        key: 'bank',
        inputs: [
            { label: 'Account Number', id: 'account_no', inputType: 'text', placeHolder: 'enter account number' },
            { label: 'Bank Name', id: 'bank_name', inputType: 'text', placeHolder: 'enter bank name' },
            { label: 'IFSC', id: 'ifsc', inputType: 'text', placeHolder: 'enter IFSC code' },
            { label: 'Branch Name', id: 'branch', inputType: 'text', placeHolder: 'enter branch name' },
            { label: 'Account holder name', id: 'holder_name', inputType: 'text', placeHolder: 'enter account holder name' },
        ],
    },
];

interface FormData {
    [key: string]: { [key: string]: string | undefined } | undefined;
}

type AlertType = {
    type: 'error' | 'success', message: string
}

const validateDate = (date: string) => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    if (!regex.test(date)) {
        return false;
    }
    return true;
};

const Docs = () => {
    const [formData, setFormData] = useState<FormData>({});
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [alert, setAlert] = useState<AlertType | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [medium, setMedium] = useState<any[]>([]);
    const navigate = useNavigate();
    const isTrottle = useRef(false);

    useEffect(() => {
        if(!isTrottle.current){
            fetchFiles();
            isTrottle.current = true
        }
    }, []);


    const handleInputChange = (
        sectionKey: string,
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ): void => {
        const { name, value } = e.target;

        if (['contact1', 'contact2'].includes(name) && !/^\d*$/.test(value)) {
            return showAlert({ type: 'error', message: 'Input value must be in number' });
        }

        const isBackspace = (e.nativeEvent as InputEvent).inputType === 'deleteContentBackward';
        if (sectionKey === "driving_license" && ['issue_date', 'valid_upto'].includes(name)) {
            const input = value.replace(/\D/g, '');
            let formattedDate = '';

            if (input.length > 0) {
                formattedDate += input.slice(0, 2);
            }
            if (input.length >= 2) {
                if (!isBackspace || (isBackspace && input.length > 2)) {
                    formattedDate += '-' + input.slice(2, 4);
                }
            }
            if (input.length >= 4) {
                if (!isBackspace || (isBackspace && input.length > 4)) {
                    formattedDate += '-' + input.slice(4, 8);
                }
            }
            const dateParts = formattedDate.split('-');
            if (dateParts.length === 3) {
                const month = parseInt(dateParts[1], 10);
                const day = parseInt(dateParts[0], 10);

                if (month > 12) {
                    return showAlert({ type: 'error', message: 'Month cannot be greater than 12' });
                }
                if (day > 31) {
                    return showAlert({ type: 'error', message: 'Day cannot be greater than 31' });
                }
            }
            setFormData((prevData) => ({
                ...prevData,
                [sectionKey]: {
                    ...prevData[sectionKey],
                    [name]: formattedDate,
                },
            }));

            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            return;
        }

        setFormData((prevData) => ({
            ...prevData,
            [sectionKey]: {
                ...prevData[sectionKey],
                [name]: value,
            },
        }));

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        sections.forEach(({ key, inputs }) => {
            inputs.forEach(({ id }) => {
                const sectionData = formData[key];
                if (!sectionData || !sectionData[id]) {
                    newErrors[id] = 'This field is required.';
                }
            });
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (formData?.driving_license) {
            if (!validateDate(formData?.driving_license?.issue_date || '') || !validateDate(formData?.driving_license?.valid_upto || '')) return showAlert({ type: 'error', message: 'Invalid date format in driving license!' });
        }
        // saveToServer(formData);
        if (validateForm() && formData.medium) {
            console.log('Form submitted successfully:', formData);
            saveToServer(formData);
        } else {
            console.log(errors);
            showAlert({ type: "error", message: "Please fillup all the necessary fields" });
        }
    }

    const fetchFiles = async () => {
        const token = localStorage.getItem("token");
        if (token) {
            const parsedToken = JSON.parse(token);
            const baseUrl = process.env.REACT_APP_API_URL;
            try {
                const response = await axios.get(baseUrl +
                    "/api/documents",
                    {
                        headers: {
                            Authorization: `Bearer ${parsedToken}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response);
                const responseData = response.data;
                if (responseData) {
                    if (responseData.bank) setFormData((prevData) => ({ ...prevData, bank: responseData?.bank }));
                    if (responseData.driving_license) setFormData((prevData) => ({ ...prevData, driving_license: responseData?.driving_license }));
                    if (responseData.car_rc) setFormData((prevData) => ({ ...prevData, car_rc: responseData?.car_rc }));
                    if (responseData.family_contact) setFormData((prevData) => ({ ...prevData, family_contact: responseData?.family_contact }));
                    if (responseData.mediums) setMedium(responseData.mediums);
                    if (responseData.cab) setFormData((prevData) => ({ ...prevData, medium: { medium: responseData?.cab?.medium_id } }));
                }
            } catch (error: any) {
                const err = error?.response?.data;
                if (err && err?.code === "token_error") {
                    window.location.replace("/driver/signin");
                }
                console.log("An unexpected error occurred:", err);
                showAlert({ type: 'error', message: err?.errorMessage });
            }
        } else {
            window.location.replace("/driver/signin");
        }
    };

    const saveToServer = async (form: any) => {
        const token = localStorage.getItem("token");
        if (token) {
            const parsedToken = JSON.parse(token);
            const baseUrl = process.env.REACT_APP_API_URL;
            try {
                setIsSaving(true);
                const response = await axios.post(baseUrl + "/api/documents", form,
                    {
                        headers: {
                            Authorization: `Bearer ${parsedToken}`,
                            // 'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response);
                navigate("/driver/files");
                // onUpdated({ name: doc.name, value: form.license_no, data: form });

            } catch (error: any) {
                const errorMessage = error.response?.data?.errorMessage || error.message;
                console.log(errorMessage);
                showAlert({ type: 'error', message: errorMessage });
            } finally {
                setIsSaving(false);
            }
        }

    };

    const showAlert = (data: AlertType) => {
        setAlert({ type: data.type, message: data.message })
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }

    const mediumOptions = medium.map(m => ({ label: m.medium_type, value: m.medium_id, description: m.medium_description }));
    const mediumInput = [{ label: 'Medium', id: 'medium', inputType: 'select', options: [{ label: "--Select Medium--", value: "" }, ...mediumOptions] }]

    return (
        <div className={styles.page}>
            {
                alert &&
                <AlertMessage type={alert.type} message={alert.message} />
            }
            <div className={styles.section}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <SectionInput
                        key={'medium'}
                        data={formData['medium'] || {}}
                        title={'Medium'}
                        inputs={mediumInput}
                        onChange={(e) => handleInputChange("medium", e)}
                    />
                    {sections.map(({ title, inputs, key }) => (
                        <SectionInput
                            key={key}
                            data={formData[key] || {}}
                            title={title}
                            inputs={inputs}
                            onChange={(e) => handleInputChange(key, e)}
                        />
                    ))}
                    <DynamicButton buttonType={'submit'} label={isSaving ? 'Saving....' : 'Save'} isHaveBackground={true} isRounded={false} disabled={isSaving} />
                </form>
            </div>
        </div>
    );
};

interface SectionInputProps {
    data: { [key: string]: string | undefined };
    title: string;
    inputs: Input[];
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const SectionInput: React.FC<SectionInputProps> = ({ data = {}, title, inputs, onChange }) => {
    return (
        <div className={styles.sectionInput}>
            <h2 className={styles.title}>
                <span>{title}</span><span>*</span>
            </h2>
            <div className={styles.inputs}>
                {inputs.map((input) => (
                    input.inputType === 'select' ? (
                        <SelectInput
                            key={input.id}
                            id={input.id}
                            label={input.label}
                            name={input.id}
                            value={data[input.id] || ''}
                            options={input.options || []}
                            onChange={onChange}
                        />
                    ) : (
                        <StandardInput
                            key={input.id}
                            id={input.id}
                            label={input.label}
                            name={input.id}
                            value={data[input.id] || ''}
                            inputType={input.inputType}
                            placeHolder={input.placeHolder}
                            maxLenght={['contact1', 'contact2'].includes(input.id) ? 10 : 10000}
                            onChange={onChange}
                        />
                    )
                ))}
            </div>
        </div>
    );
};

export default Docs;
