import React from 'react'
import styles from './Download.module.css'
function Download() {
    const handleRedirect = (url: string) => {
        window.location.href = url;
    };
    return (
        <section className={styles.container}>
            <img
                src="/googleplay.svg"
                alt="Google Play"
                onClick={() => handleRedirect('/alert')}
                height="auto"
                width="auto"
                title="Google Play Icon"
            />
            <img
                src="/appstore.svg"
                alt="App Store"
                onClick={() => handleRedirect('/alert')}
                height="auto"
                width="auto"
                title="App Store Icon"
            />
        </section>
    )
}

export default Download